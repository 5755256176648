*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 100%;
}
[v-cloak] {
  display: none !important;
}
#email-form {
  margin-top: 20px;
}
.client_id {
  padding-bottom: 10px;
}
.hidden {
  display: none !important;
}
.error_label {
  color: #f00;
  font-size: 14px;
}
.sub_block {
  margin-top: 10px;
}
.is_hidden {
  display: none;
}
.field--error {
  color: #f00;
  font-size: 10px;
}
div > p:last-child {
  margin-bottom: 0;
}
.popover {
  border-radius: 0;
}
.popover-static {
  position: relative;
  max-width: 100%;
  z-index: 1;
}
.popover-comment.popover-bottom:before,
.popover-comment.popover-bottom:after {
  left: 20px;
  border-right: 0;
  border-left-width: 31px;
}
.popover-comment.popover-bottom:before {
  border-bottom-width: 12px;
  top: -12px;
  border-left-width: 34px;
}
.popover-comment.popover-bottom:after {
  border-bottom-color: #fff;
}
.ui-icon.material-icons {
  font-size: 1.3em;
  line-height: 0.8;
  height: auto;
}
.card {
  border-radius: 0;
}
.card-blue {
  border-color: #0275d8;
}
.card-blue .card-header {
  color: #0275d8;
}
.card-clean {
  border: 0;
  border-radius: 0;
}
.card-clean .card-header {
  color: #53a4d9;
  background-color: transparent;
}
.card-clean .card-header .card-subheader {
  margin-top: 0.25em;
  margin-bottom: -0.3em;
}
.list-group-item--checkbox {
  padding: 0.75rem;
}
.list-group-item--checkbox .custom-control.custom-checkbox {
  margin: 0;
  display: block;
  width: 100%;
}
body {
  color: #898989;
}
.app-container {
  padding-bottom: 2em;
}
.app-container__head,
.app-container__body {
  padding: 0.75em;
}
.app-container__body {
  border: 1px solid #8fcdf4;
}
.app-container__head {
  padding-top: 2.25em;
  padding-bottom: 2.25em;
}
.form-control {
  border-radius: 0;
  color: #000;
  border-color: #c8c8c8;
}
.list-group {
  border-radius: 0;
  border-color: #c8c8c8;
}
.list-group-item {
  border-radius: 0 !important;
  border-color: #c8c8c8;
}
h1:not([class]),
h2:not([class]),
h3:not([class]),
h4:not([class]),
h5:not([class]),
h6:not([class]) {
  margin-top: 2em;
  margin-bottom: 1em;
}
h1,
h2,
h3 {
  color: #53a4d9;
}
a {
  color: #53a4d9;
}
.btn {
  border-radius: 999px;
}
.btn-default {
  background: #fff;
  color: #53a4d9;
  border-color: #53a4d9;
}
.btn-default:not(.no-hover):hover {
  color: #fff;
  background: #53a4d9;
}
.btn-primary {
  color: #fff;
  background: #53a4d9;
  border-color: #53a4d9 !important;
}
.btn-primary:hover {
  background: #fff;
  color: #53a4d9;
}
.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active,
.page-item.active .page-link {
  background: #53a4d9;
}
.thead-inverse th {
  background: #7895b1;
}
.text-blue {
  color: #53a4d9;
}
.is-clickable {
  cursor: pointer;
}
.is-unclickable {
  cursor: error;
}
.picker .flexbox {
  display: flex;
  flex-wrap: wrap;
}
.picker .flexbox div {
  flex-grow: 1;
  padding: 10px 0;
}
.picker .flexbox .item {
  flex: 1;
  flex-basis: 25%;
}
.picker .flexbox {
  padding: 0px;
}
.picker .prev:hover,
.next:hover {
  cursor: pointer;
  background-color: #add8e6;
}
.picker .monthItem .item {
  border-top: 1px solid #add8e6;
}
.picker .monthItem .item:hover {
  cursor: pointer;
  background-color: #add8e6;
}
.tree-collapsible {
  margin-bottom: 1.5em;
}
.tree-collapsible .ui-collapsible__header {
  padding: 0.25em 0.75em;
  min-height: 0em;
  background: transparent;
  border-bottom: 1px solid #53a4d9;
}
.tree-collapsible .ui-collapsible__body {
  padding: 0;
  padding-left: 3em;
  border: 0;
}
.tree-collapsible .ui-collapsible .list-group-item {
  padding: 0.25em;
  border-width: 0;
  border-top-width: 1px;
}
.tree-collapsible .ui-collapsible .list-group-item:first-child {
  border-top: 0;
}
.monitor--ok {
  background-color: #27ae60;
  text-align: center;
  padding: 10px;
  color: #fff;
}
.monitor--error {
  background-color: #e74c3c;
  text-align: center;
  padding: 10px;
  color: #fff;
}
.break-word {
  word-wrap: break-word;
  max-width: 300px;
}
.admin--active {
  color: #fff;
  background: #53a4d9;
}
.is-sync {
  background-color: #2ecc71;
}
.not-sync {
  background-color: #e74c3c;
}
.table-danger,
.table-danger td,
.table-danger th {
  background-color: #e74c3c !important;
  color: #fff;
}
.table-danger td a {
  color: #fff;
}
.table-warning,
.table-warning td,
.table-warning th {
  background-color: #ffdd2d !important;
  color: #333;
}
.table-warning td a {
  color: #333;
}
.action-icon {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer;
}
.emailResponse {
  max-height: 120px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .form__action_button {
    margin-top: 27px;
  }
}
@import url("https://fonts.googleapis.com/css?family=Roboto:300");
